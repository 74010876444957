import { render, staticRenderFns } from "./photovoltaicBox14.vue?vue&type=template&id=f310ece6&scoped=true"
import script from "./photovoltaicBox14.vue?vue&type=script&lang=js"
export * from "./photovoltaicBox14.vue?vue&type=script&lang=js"
import style0 from "./photovoltaicBox14.vue?vue&type=style&index=0&id=f310ece6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f310ece6",
  null
  
)

export default component.exports